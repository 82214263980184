import axios from 'axios';
import analytics from 'libs/analytics';
import * as CookieStore from 'libs/storage/CookieStore';
import {
  addHeaderToAllAPIRequests,
  APIClient,
  MentalAPIResponse,
  MentalAPIResponseWithMessage,
} from 'models/APIClient';
import React from 'react';

type PhoneNumberString = string; // includes the + sign
type CountryCode = string; // two-character ISO field
type CountryPrefix = string; // Includes the + sign
type VerificationCode = string; // 6 characters

interface PhoneNumber {
  phone_number: PhoneNumberString, // Includes the + sign.
  country_code: CountryCode, // two-character ISO field
  country_prefix: CountryPrefix, // Includes the + sign
}

export interface APIUser {
  id: string,
  phone_number?: string,
  email?: string,
  user_auth_method?: string,
}

export interface User extends Omit<APIUser, 'created_at'> {
  is_verified: boolean,
}

type UserParams = {
  phone_number: PhoneNumber,
};

interface UserAuthSession {
  id: string,
  token: string,
  user_id: string,
  device_id: string,
  expires_at: Date,
  is_verified?: boolean,
}

export interface UserAndAuth {
  user: APIUser,
  user_is_new?: boolean,
  user_auth_session: UserAuthSession,
}

export const postPhoneNumber = async (user: UserParams): Promise<MentalAPIResponseWithMessage<UserAndAuth>> => {
  const response = await APIClient.post<UserAndAuth>('/user/phone_number', { user, signup_source: 'mental' });
  return handlePostPhoneNumberResponse(response);
};

export const resendVerificationCode = async (): Promise<MentalAPIResponseWithMessage<UserAndAuth>> => {
  const response = await APIClient.post<UserAndAuth>('/user/phone_number/resend', { signup_source: 'mental' });
  return handlePostPhoneNumberResponse(response);
};

const handlePostPhoneNumberResponse = async (response: MentalAPIResponse<UserAndAuth>): Promise<MentalAPIResponseWithMessage<UserAndAuth>> => {
  if (APIClient.didSucceed(response)) {
    const { data } = response.successRes;
    await persistUserCredentials(data, false);
    return response;
  }
  if (APIClient.didFail(response)) {
    const { data } = response.failureRes;
    if (data.code === 'invalid_phone_number') {
      return {
        ...response,
        frontendErrorMessage: 'That phone number looks invalid. Double-check it and try again.',
      };
    }
    return {
      ...response,
      frontendErrorMessage: 'We were unable to send a text message to that phone number.',
    };
  }
  return {
    ...response,
    frontendErrorMessage: 'There was a problem sending a text message to that phone number.',
  };
};

export const verifyPhoneNumber = async (verificationCode: VerificationCode): Promise<MentalAPIResponseWithMessage<UserAndAuth>> => {
  const response = await APIClient.post<UserAndAuth>('/user/phone_number/verify', { verification_code: verificationCode });
  if (APIClient.didSucceed(response)) {
    await CookieStore.set('user_login_method', 'phone_number');

    const { data } = response.successRes;
    await persistUserCredentials(data, true);
    return response;
  }
  if (APIClient.didFail(response)) {
    const { data } = response.failureRes;
    if (data.code === 'incorrect_verification_code') {
      return {
        ...response,
        frontendErrorMessage: 'That code is wrong.\nDouble-check it and try again.',
      };
    }
  }
  return {
    ...response,
    frontendErrorMessage: 'There was a problem verifying your phone number. Please try again.',
  };
};

export const fetchGoogleUserInfo = async (googleUser: { access_token: string }) => {
  const response = await axios
    .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${googleUser.access_token}`, {
      headers: {
        Authorization: `Bearer ${googleUser.access_token}`,
        Accept: 'application/json',
      },
    });

  const { data } = response;

  const {
    id: google_user_id,
    email,
    given_name: name_given,
    family_name: name_family,
    name: name_full,
    picture: photo_url,
  } = data;

  const googleAuthResponse = await APIClient.post<UserAndAuth>('/user/auth/google', {
    google_user_params: {
      google_user_id,
      email,
      name_given,
      name_family,
      name_full,
      photo_url,
      identity_token: '',
    },
    signup_source: 'mental',
  });
  if (APIClient.didSucceed(googleAuthResponse)) {
    await handlePostGoogleAuth(googleAuthResponse);
  }

  return googleAuthResponse;
};

export const handlePostGoogleAuth = async (response: MentalAPIResponse<UserAndAuth>): Promise<MentalAPIResponseWithMessage<UserAndAuth>> => {
  if (APIClient.didSucceed(response)) {
    await CookieStore.set('user_login_method', 'google');

    const { data } = response.successRes;
    await persistUserCredentials(data, true);
    return response;
  }

  return {
    ...response,
    frontendErrorMessage: 'There was a problem sending a text message to that phone number.',
  };
};

export const subscribeEmailToOneSignal = async (
  email: string,
  tags: { [key: string]: string },
) => {
  const oneSignalResponse = await APIClient.post('/user/onesignal/subscribe', {
    email,
    tags,
  });
  if (!APIClient.didSucceed(oneSignalResponse)) {
    return {
      error: 'There was a problem subscribing to one signal.',
    };
  }
};

export const handlePostAppleSuccess = async (
  params: {
    authorization_code: string,
    identity_token: string,
    name_given: string,
    name_family: string,
    email: string,
  },
) => {
  const appleAuthResponse = await APIClient.post<UserAndAuth>('/user/auth/apple/web', {
    apple_user_params: params,
    signup_source: 'mental',
  });
  if (APIClient.didSucceed(appleAuthResponse)) {
    await CookieStore.set('user_login_method', 'apple');

    const { data } = appleAuthResponse.successRes;
    await persistUserCredentials(data, true);
  }
  return appleAuthResponse;
};

const persistUserCredentials = async (userAndAuth: UserAndAuth, isVerified: boolean) => {
  // TODO: When we detect any `unauthorized_user` error from the API, we need
  // a way to reauthenticate without them having to put in their auth again.
  // For example, if they change their phone number we could invalidate all existing sessions?
  // Not an MVP blocker, because we expect this to be rare / not happen at all.
  await saveUserAuthSessionToken(userAndAuth.user_auth_session.token);

  const apiUser = userAndAuth.user;
  const user = {
    ...apiUser,
    is_verified: isVerified,
  };
  await saveUser(user);

  analytics.identifyUser(user);
  analytics.updateUserProperties({
    phone_number: user.phone_number,
  });
};

const UserAuthSessionTokenKey = 'user.auth_session.token';
const saveUserAuthSessionToken = async (token: string) => {
  return CookieStore.set(UserAuthSessionTokenKey, token);
};

export const getUserAuthSessionToken = async (): Promise<string | undefined> => {
  return CookieStore.get(UserAuthSessionTokenKey);
};

addHeaderToAllAPIRequests(async () => ({
  'x-mental-user-auth-session-token': await getUserAuthSessionToken(),
}));

const UserKey = 'user';
const saveUser = async (user: User | undefined) => {
  return CookieStore.setJSON(UserKey, user);
};

export const getUser = async (): Promise<User | undefined> => {
  return CookieStore.getJSON<User>(UserKey);
};

export const getVerifiedUser = async (): Promise<User | undefined> => {
  const user = await getUser();
  if (!user?.is_verified) return undefined;
  return user;
};

// undefined means it's being fetched (e.g. undetermined), while null means we're not logged in
export const useVerifiedUser = (): User | undefined | null => {
  const [user, setUser] = React.useState<User | undefined | null>(undefined);

  React.useEffect(() => {
    (async () => {
      const fetchedUser = await getVerifiedUser();
      setUser(fetchedUser ?? null);
    })();
  }, []);

  return user;
};

let isLoggingOut = false;
export const logOutUser = async () => {
  if (isLoggingOut) return;
  isLoggingOut = true;

  await CookieStore.remove(UserKey);
  await CookieStore.remove(UserAuthSessionTokenKey);
  // removes OneSignal ID and generate a new one
  // await OneSignal.logout();

  isLoggingOut = false;
};

let isDeletingUser = false;
export const deleteUser = async () => {
  if (isDeletingUser) return;
  isDeletingUser = true;

  const response = await APIClient.delete('/user');
  if (APIClient.didSucceed(response)) {
    logOutUser();
  }

  isDeletingUser = false;
};

export const useUser = (): User | undefined => {
  const [user, setUser] = React.useState<User | undefined>(undefined);

  React.useEffect(() => {
    (async () => {
      const fetchedUser = await getUser();
      setUser(fetchedUser);
    })();
  }, []);

  // TODO: Update the user whenever it changes?
  // In the app, we did this with a PubSub object

  return user;
};
