import axios, {
  AxiosError, AxiosRequestConfig,
  AxiosResponse, Method,
} from 'axios';
import { isRight } from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import { StatusCodes } from 'http-status-codes';
import * as D from 'io-ts/Decoder';
import { AnalyticsProperties } from 'libs/analytics';
import * as CookieStore from 'libs/storage/CookieStore';
// import { isEnvLocal, mentalEnvironment } from 'libs/env';
import { removeNils } from 'libs/typescript';
import { DateTime, Duration } from 'luxon';
import { dJSONObject, JSONObject } from 'types/json';
import { v4 as uuidv4 } from 'uuid';

const isNgrok = typeof window !== 'undefined' && window.location.hostname.includes('ngrok.');
const baseURL = isNgrok ? 'https://mental-api-local.ngrok.app' : process.env.NEXT_PUBLIC_API_URL;

const baseHeaders = {
  Accept: 'application/json',
};

// TODO: Get this from the API's types?
const mentalErrorCodes = [
  'invalid_body',
  'invalid_query',
  'invalid_json',
  'invalid_id',
  'invalid_phone_number',
  'incorrect_verification_code',
  'unauthorized_device',
  'unauthorized_user',
  'deleted_user',
  'unauthorized_admin_user',
  'uncaught_exception',
  'failed_to_send_sms',
  'unknown',
] as const;
export type MentalErrorCode = typeof mentalErrorCodes[number];

// useful in a component for keeping track of the state of an API call
// not used directly by this file
export type APICallStatus = 'unsent' | 'sending' | 'success' | 'error';

interface MentalErrorResponse {
  code: MentalErrorCode,
  message?: string,
  errors?: object[],
  info?: JSONObject,
}

// See https://github.com/gcanti/io-ts/blob/master/Decoder.md for docs
// on how this decoder works. We use this to validate the incoming error data.
const MentalErrorResponseDecoder = pipe(
  D.struct({
    code: D.string,
  }),
  D.intersect(
    D.partial({
      message: D.string,
      errors: D.UnknownRecord,
      info: dJSONObject,
    }),
  ),
);

interface MentalAPISuccessResponse<ResponseData = any> {
  successRes: AxiosResponse<ResponseData>,
}

interface MentalAPIFailureResponse {
  failureRes: AxiosResponse<MentalErrorResponse>,
  frontendErrorMessage?: string,
  error: AxiosError,
}

interface MentalAPIFailureResponseWithMessage extends MentalAPIFailureResponse {
  frontendErrorMessage: string,
}

interface MentalAPIUnexpectedErrorResponse {
  unexpectedError: unknown,
}

interface MentalAPIUnexpectedErrorResponseWithMessage extends MentalAPIUnexpectedErrorResponse {
  frontendErrorMessage: string,
}

export type MentalAPIResponse<ResponseData = any> =
  | MentalAPISuccessResponse<ResponseData>
  | MentalAPIFailureResponse
  | MentalAPIUnexpectedErrorResponse;

// includes a non-optional frontendErrorMessage for the failure case
// this allows model that called the API to respond with a user-friendly error UI
export type MentalAPIResponseWithMessage<ResponseData = any> =
  | MentalAPISuccessResponse<ResponseData>
  | MentalAPIFailureResponseWithMessage
  | MentalAPIUnexpectedErrorResponseWithMessage;

export const APITimeouts = {
  default: Duration.fromObject({ seconds: 30 }).as('milliseconds'),
  long: Duration.fromObject({ minutes: 1 }).as('milliseconds'),
  veryLong: Duration.fromObject({ minutes: 2.5 }).as('milliseconds'),
};

const axiosInstance = axios.create({
  baseURL,
  responseType: 'json',
  headers: baseHeaders,
  timeout: APITimeouts.default,
});

/* We MUST have a device in the API's DB before making all subsequent API calls.
 * Therefore, if we make many requests simultaneously on the first ever app open, some will fail.
 * So we hold all requests that aren't that initial `POST /device` call until that first one succeeds.
 * This only occurs once per app install (we persist this boolean in Secure Storage — the same place we keep the device id).
 */
const HasSuccessfullyPostedDeviceKey = 'HasSuccessfullyPostedDevice';
const TrueSentinel = 'true';
const hasSuccessfullyPostedDevice = async (): Promise<boolean> => {
  const string = CookieStore.get(HasSuccessfullyPostedDeviceKey);
  return string === TrueSentinel;
};

const setHasSuccessfullyPostedDevice = async () => {
  await CookieStore.set(HasSuccessfullyPostedDeviceKey, TrueSentinel);
};

let blockingAPICallPromise: Promise<void> | undefined;
let blockingAPICallPromiseResolver: (() => void) | undefined;

type APIRequestConfig<T = any> = AxiosRequestConfig<T> & {
  canSkipInitialDevicePost?: boolean,
};

export abstract class APIClient {
  public static async request<ResponseData = any, RequestData = any>(
    method: Method,
    url: string,
    data?: RequestData,
    additionalConfig?: APIRequestConfig<RequestData>,
  ): Promise<MentalAPIResponse<ResponseData>> {
    const requestId = uuidv4();
    // Handle GET query params and POST body the same way, as just "data"
    // This makes the call interface less different between different HTTP methods
    const dataIsParams = ['delete', 'get', 'head', 'options'].includes(method.toLowerCase());
    const headers = {
      'x-mental-request-id': requestId,
      'x-mental-device-timezone': DateTime.local().zoneName,
      ...additionalConfig?.headers,
    };
    const config: AxiosRequestConfig<RequestData> = {
      ...additionalConfig,
      method,
      url,
      params: dataIsParams ? data : (additionalConfig?.params ?? undefined),
      data: dataIsParams ? (additionalConfig?.data ?? undefined) : data,
      headers,
    };

    // const routeSignature = `${method.toUpperCase()} ${url}`;
    // const sharedLoggingProps = {
    //   requestId,
    //   routeSignature,
    //   baseURL,
    // };

    const isOnServer = typeof window === 'undefined';
    const canSkipInitialDevicePost = isOnServer || additionalConfig?.canSkipInitialDevicePost;
    const isDevicePOST = (method.toLowerCase() === 'post' && url === '/device');
    const hasEverPostedDevice = await hasSuccessfullyPostedDevice();
    const isBlockingAPICall = isDevicePOST && !hasEverPostedDevice;
    const mustAwaitBlockingAPICall = !isDevicePOST && !hasEverPostedDevice && !canSkipInitialDevicePost;

    if (!blockingAPICallPromise && (isBlockingAPICall || mustAwaitBlockingAPICall)) {
      blockingAPICallPromise = new Promise((resolve) => {
        blockingAPICallPromiseResolver = resolve;
      });
    }

    if (mustAwaitBlockingAPICall) {
      await blockingAPICallPromise;
    }

    // Axios throws an error whenever we get a non-200 response.
    // This is burdensome on all callers, as even if they wish to ignore errors, they must still try/catch,
    // and any failures to do so properly are fatal. It also exposes things like axios.isAxiosError to every callsite.
    // Instead, we catch, parse and validate API errors internally, and return in the response, but without a thrown error.
    // This also allows us to handle the Mental API's error response format in a typesafe way (see MentalErrorResponse).
    try {
      // MentalLogger.verbose('API: Making Request', {
      //   ...sharedLoggingProps,
      //   ...config,
      //   headers: {
      //     ...(config.headers ?? {}),
      //     ...baseHeaders,
      //   },
      // });

      const successRes = await axiosInstance.request<ResponseData>({
        ...config,
      });

      if (isBlockingAPICall) {
        // Note that a failure for that first ever `POST /device` will throw, and not get here
        // This will block all subsequent requests from happening, but they're bound to fail on the API anyway.
        // On the next launch of the app, we'll try again and hopefully it'll succeed.
        // This could occur if the user is offline on first ever app launch, so we'll need to consider this case.
        // This could also occur if our API is down for any reason, which means the client can't recover.
        // TODO: Log when this occurs to assess impact of it.
        // TODO: Perhaps implement a timeout of some kind in the catch block: `if (isBlockingAPICall) setTimeout(resolve(), 10000)
        await setHasSuccessfullyPostedDevice();
        blockingAPICallPromiseResolver?.();
        blockingAPICallPromise = undefined;
        blockingAPICallPromiseResolver = undefined;
      }

      // MentalLogger.verbose('API: Success', {
      //   ...sharedLoggingProps,
      //   response: {
      //     data: successRes.data,
      //     status: successRes.status,
      //     headers: successRes.headers,
      //   },
      // });

      return { successRes };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const failureRes = error.response;
        if (failureRes) {
          const validatedErrorData = MentalErrorResponseDecoder.decode(failureRes?.data);
          if (isRight(validatedErrorData)) {
            // MentalLogger.debug('API: Failure', {
            //   ...sharedLoggingProps,
            //   response: {
            //     data: failureRes?.data,
            //     status: failureRes?.status,
            //     headers: failureRes?.headers,
            //     error: error.toJSON(),
            //   },
            // });

            const wrappedErrorResponse = failureRes as AxiosResponse<MentalErrorResponse>;

            if (wrappedErrorResponse.status === StatusCodes.GONE && wrappedErrorResponse.data.code === 'deleted_user') {
              // MentalLogger.info('API: User was deleted, logging out');
              // UserPubSub.publish({
              //   eventType: 'should-log-out',
              // });
            }

            return {
              failureRes: wrappedErrorResponse,
              error,
            };
          }
          // const decodeError = D.draw(validatedErrorData.left);
          // MentalLogger.warn('API: Error Decoding Mental Response', {
          //   ...sharedLoggingProps, decodeError, error, responseData: failureRes?.data,
          // });
        }
      }

      // MentalLogger.warn('API: Unexpected Error', { ...sharedLoggingProps, error });
      return { unexpectedError: error };
    }
  }

  // common verb helpers

  public static async get<ResponseData = any, RequestData = any>(
    url: string,
    data?: RequestData,
    additionalConfig?: APIRequestConfig<RequestData>,
  ): Promise<MentalAPIResponse<ResponseData>> {
    return this.request('GET', url, data, additionalConfig);
  }

  public static async post<ResponseData = any, RequestData = any>(
    url: string,
    data?: RequestData,
    additionalConfig?: APIRequestConfig<RequestData>,
  ): Promise<MentalAPIResponse<ResponseData>> {
    return this.request('POST', url, data, additionalConfig);
  }

  public static async delete<ResponseData = any, RequestData = any>(
    url: string,
    data?: RequestData,
    additionalConfig?: APIRequestConfig<RequestData>,
  ): Promise<MentalAPIResponse<ResponseData>> {
    return this.request('DELETE', url, data, additionalConfig);
  }

  public static didSucceed<ResponseData = any>(response: MentalAPIResponse): response is MentalAPISuccessResponse<ResponseData> {
    return Object.prototype.hasOwnProperty.call(response, 'successRes');
  }

  public static didFail(response: MentalAPIResponse): response is MentalAPIFailureResponse {
    return Object.prototype.hasOwnProperty.call(response, 'failureRes');
  }

  public static didGetUnexpectedError(response: MentalAPIResponse): response is MentalAPIUnexpectedErrorResponse {
    return Object.prototype.hasOwnProperty.call(response, 'unexpectedError');
  }

  public static throwable(response: MentalAPIFailureResponse | MentalAPIUnexpectedErrorResponse): AxiosError | unknown {
    if (this.didFail(response)) return response.error;
    return response.unexpectedError;
  }

  public static throw(response: MentalAPIFailureResponse | MentalAPIUnexpectedErrorResponse) {
    throw this.throwable(response);
  }
}

type NullableHeaders = Record<string, string | null | undefined>;
type HeaderGenFn = () => Promise<NullableHeaders>;
const headerGenFns: HeaderGenFn[] = [];
export const addHeaderToAllAPIRequests = (headerGenFn: HeaderGenFn) => {
  headerGenFns.push(headerGenFn);

  axiosInstance.interceptors.request.use(async (config) => {
    /* eslint-disable no-param-reassign */
    const newHeaders = await headerGenFn();
    config.headers = config.headers || {};
    Object.entries(removeNils(newHeaders)).forEach(([key, value]) => {
      if (!(key in config.headers)) {
        (config.headers as any)[key] = value;
      }
    });
    return config;
  });
};

// const getAllGeneratedHeaders: HeaderGenFn = async () => {
//   const allHeaders = await Promise.all(headerGenFns.map((headerGenFn) => headerGenFn()));
//   return allHeaders.reduce((acc, headers) => ({ ...acc, ...headers }), {});
// };

export const eventPropsForResponse = (response: MentalAPIResponse): AnalyticsProperties => {
  if (APIClient.didFail(response)) {
    return eventPropsForFailedResponse(response);
  }
  // TODO: Other common props for successful or unexpected responses?
  return {};
};

export const eventPropsForFailedResponse = (response: MentalAPIFailureResponse): AnalyticsProperties => {
  return {
    mental_api_error_code: response.failureRes.data.code,
    mental_api_error_message: response.failureRes.data.message,
    error_message: response.frontendErrorMessage,
    http_error_code: response.error.code,
    http_error_status: response.error.status,
  };
};
